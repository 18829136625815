import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import queryString from "query-string";
import { useParams, useLocation } from "react-router-dom";
import {
  Button,
  Spinner,
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  Modal,
} from "react-bootstrap";
import { firestore } from "../services/firebase";
import { events } from "../components/events";
import moment from "moment";
import * as _ from "lodash";
import firebase from "firebase";
import StarRatings from "react-star-ratings";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faBars } from "@fortawesome/free-solid-svg-icons";
import profileImg from "../assets/images/Profile.png";
import logoImg from "../assets/images/Logo.png";

import mealIcon from "../assets/icons/features/vegetables.png";
import workoutIcon from "../assets/icons/features/workout.png";
import groupIcon from "../assets/icons/features/group.png";
import chatIcon from "../assets/icons/features/chat.png";
import callIcon from "../assets/icons/features/call.png";
import questionIcon from "../assets/icons/features/question.png";
import trophyIcon from "../assets/icons/features/trophy.png";
import statsIcon from "../assets/icons/features/stats.png";
import { NavLink } from "react-router-dom";
import { Login } from "./login/LoginModal";

import axios from "axios";

import "./Challenge.scss";

var featuresMap = {
  challenge: {
    mealPlan: { title: "Meal plan", image: mealIcon },
    workoutPlan: { title: "Workout plan", image: workoutIcon },
    liveSessions: { title: "Live sessions", image: groupIcon },
    //groupSupport: {title: 'Group support', image: groupIcon},
    chatSupport: { title: "Group chat", image: chatIcon },
    askQuestions: { title: "Ask coaches", image: questionIcon },
    leaderBoard: { title: "Weekly rankings", image: trophyIcon },
    statistics: { title: "Performance statistics", image: statsIcon },
  },
  program: {
    mealPlan: { title: "Meal plan", image: mealIcon },
    workoutPlan: { title: "Workout plan", image: workoutIcon },
    chatSupport: { title: "Direct chat", image: chatIcon },
    liveCalls: { title: "Live calls", image: callIcon },
    statistics: { title: "Progress tracking", image: statsIcon },
  },
};

export default function Challenge(props) {
  const [loader, setLoader] = useState(true);
  const [allChallenges, setAllChallenges] = useState();
  const [selectedChallenge, setSelectedChallenge] = useState();
  const [challenge, setChallenge] = useState();
  const [challenges, setChallenges] = useState();
  const [otherChallenges, setOtherChallenges] = useState();
  const [nutritioinistShow, setNutritioinistShow] = useState();
  const [nutritionist, setNutritioinist] = useState();
  const [reviews, setReviews] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [ignore, setIgnore] = useState({});
  const [isUserFollowing, setIsUserFollowing] = useState(false);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const { search } = useLocation();
  const values = queryString.parse(search);
  const [src, setSrc] = useState(
    window.location.href.includes("fbclid")
      ? "facebook"
      : values.utm_source || values.src || null
  );
  const [defaultGrace, setDefaultGrace] = useState(3);
  const [oneToOne, setOneToOne] = useState();
  const [showLogin, setShowLogin] = useState(false);
  const [collaborators, setCollaborators] = useState([]);
  const [showLoginAlert, setShowLoginAlert] = useState(false);

  let { nutritionistName, challengeName, challengeId } = useParams();
  let history = useHistory();

  const [location, setLocation] = useState();

  const getLocation = async () => {
    // if (user && user.country) return setLocation(user.country);

    try {
      const resp = await axios.get("https://ipapi.co/json/");
      setLocation(resp.data.country_code);
    } catch (err) {
      console.error(err);
      setLocation("N/A");
    }
  };

  useEffect(() => {
    var location = getLocation();
    var unsubscribes = getCollections();
    if (src) {
      localStorage.setItem("src", src);
    } else {
      localStorage.removeItem("src");
    }

    return () => {
      // console.log("Do some cleanup");
      //unsubscribes.map((unsubscribe) => unsubscribe())
    };
  }, [nutritionistName, challengeName, challengeId]);

  useEffect(() => {
    var location = getLocation();

    var unsubscribes = getChallenge();

    return () => {
      // console.log("Do some cleanup");
      if (unsubscribes && unsubscribes.map)
        unsubscribes.map((unsubscribe) => unsubscribe());
    };
  }, [selectedChallenge]);

  useEffect(() => {
    if (nutritionist && user) {
      if (window.location.href.includes("follow")) {
        ensureFollow();
      } else {
        checkIsUserFollowing();
      }
    }

    return () => {};
  }, [nutritionist]);

  async function getCollections() {
    if (challengeId) {
      //check if param is challenge id
      var challengeRef = await firestore("challenges").doc(challengeId).get();
      if (challengeRef.exists) {
        var challenge = challengeRef.data();
        challenge.id = challengeRef.id;
        setSelectedChallenge(challenge);

        if (window.mixpanel)
          window.mixpanel.track("Program_Opened", {
            challengeId: challengeId,
            challengeName: challenge.name,
          });

        //if(window.mixpanel && !(moment(challenge.date).diff(moment(), 'd') > -(parseInt(challenge.grace) || defaultGrace))) window.mixpanel.track("challenge full", {challenge: challenge.name});

        events.add({
          page: "challenge",
          challenge: { id: challengeId, name: challenge.name },
          ...(user && { user: { id: user.id, name: user.name } }),
          ...(src && { src: src }),
        });
      }
    }

    /*else*/ /*if(nutritionistName){    //try nutritionist name

            var _nutritionistName = nutritionistName
            if(nutritionistName.includes('_')) _nutritionistName = nutritionistName.split('_').map((d)=>d[0].toUpperCase() + d.slice(1)).join(' ')
            if(nutritionistName.includes('.')) _nutritionistName = nutritionistName.split('.').map((d)=>d[0].toUpperCase() + d.slice(1)).join(' ')

            var challengeRef = await firestore("challenges").where('nutritionistName', '==', _nutritionistName).get()
            if(challengeRef.size){

                var challenges = challengeRef.docs.map((d)=>{const obj = d.data(); obj.id=d.id; return obj})//.filter((d)=>d.price>0)

                challenges = _.orderBy(challenges, (item)=>moment.utc(item.date).format(), 'desc')

                setChallenges(challenges)

                setSelectedChallenge(challenges[0])
            }
        } 

        if(challengeName){    //try challenge name

            var _challengeName = challengeName
            if(challengeName.includes('_')) _challengeName = challengeName.split('_').map((d)=>d[0].toUpperCase() + d.slice(1).toLowerCase()).join(' ')
            if(challengeName.includes('.')) _challengeName = challengeName.split('.').map((d)=>d[0].toUpperCase() + d.slice(1).toLowerCase()).join(' ')

            var challengeRef = await firestore("challenges").where('name', '==', _challengeName).get()
            if(challengeRef.size){

                var challenges = challengeRef.docs.map((d)=>{const obj = d.data(); obj.id=d.id; return obj})//.filter((d)=>d.price>0)

                challenges = _.orderBy(challenges, (item)=>moment.utc(item.date).format(), 'desc')

                setChallenges(challenges)

                setSelectedChallenge(challenges[0])
            }
        }*/
    /* Getting all challenges if no data in path */
    if (!nutritionistName && !challengeName && !challengeId) {
      setAllChallenges(true);

      var _challengeRef = await firestore("challenges").get();

      if (_challengeRef.size) {
        var _challenges = _challengeRef.docs.map((d) => {
          const obj = d.data();
          obj.id = d.id;
          return obj;
        }); //.filter((d)=>d.price>0)

        _challenges = _.orderBy(
          _challenges.filter((d) => {
            return (
              d.isPublished &&
              !d.isPrivate &&
              moment().isBefore(moment(d.endDatetime))
            );
          }),
          (item) => moment.utc(item.date).format(),
          "desc"
        );
        var nutritionistsObj = {};
        const nutritionistsRef = await firestore("users")
          .where("role", "array-contains", "nutritionist")
          .get();
        if (nutritionistsRef.size) {
          var nutritionists = nutritionistsRef.docs.map((d) => {
            const obj = d.data();
            obj.id = d.id;
            return obj;
          });

          nutritionists.map((d) => {
            nutritionistsObj[d.id] = d;
          });
        }
        _challenges.map((c) => {
          c.nutritionist = nutritionistsObj[c.nutritionistId];
          return c;
        });

        var grouped = [],
          ignore = {};
        _challenges.map((d) => {
          if (d.previousRounds) {
            d.rounds = d.previousRounds.length + 1;
            d.previousRounds.map((d) => {
              ignore[d] = true;
            });
            d.roundsIds = [d.id].concat(d.previousRounds);
          }
        });

        setIgnore(ignore);
        _challenges = _challenges.filter((d) => !ignore[d.id]);

        setChallenges(_challenges);
        setLoader(false);
      }

      //if(window.mixpanel) window.mixpanel.track("challenges list");

      events.add({
        page: "challenges",
        ...(user && { user: { id: user.id, name: user.name } }),
        ...(src && { src: src }),
      });
    }
    /* Getting nutritionist data if nut name in paht */
    if (nutritionistName && !challengeName) {
      var _nutritionistName = nutritionistName;
      if (nutritionistName.includes("_"))
        _nutritionistName = nutritionistName
          .split("_")
          .map((d) => d[0].toUpperCase() + d.slice(1))
          .join(" ");
      if (nutritionistName.includes("."))
        _nutritionistName = nutritionistName
          .split(".")
          .map((d) => d[0].toUpperCase() + d.slice(1))
          .join(" ");

      const nutritionistsRef = await firestore("users")
        .where("name", "==", _nutritionistName)
        .where("role", "array-contains", "nutritionist")
        .get();
      if (nutritionistsRef.size) {
        var nutritionists = nutritionistsRef.docs.map((d) => {
          const obj = d.data();
          obj.id = d.id;
          return obj;
        });

        setNutritioinist(nutritionists[0]);

        firestore("one_to_one")
          .doc(nutritionists[0].id)
          .onSnapshot((doc) => {
            if (!doc.data()) return;

            setOneToOne({
              ...doc.data(),
              key: doc.id,
              id: doc.id,
              oneToOne: true,
            });
          });
      }

      var _challengeRef = _nutritionistName
        ? await firestore("challenges")
            .where("nutritionistId", "==", nutritionists[0].id)
            .get()
        : await firestore("challenges").get();

      if (_challengeRef.size) {
        var _challenges = _challengeRef.docs.map((d) => {
          const obj = d.data();
          obj.id = d.id;
          return obj;
        }); //.filter((d)=>d.price>0)

        _challenges = _.orderBy(
          _challenges.filter(
            (d) =>
              d.isPublished &&
              !d.isPrivate &&
              moment().isBefore(moment(d.endDatetime))
          ),
          (item) => moment.utc(item.date).format(),
          "desc"
        );

        if (
          !_challenges.length &&
          (await isNutOneToOnePublished(_nutritionistName))
        ) {
          history.push(
            `${nutritionistName}/one.to.one` + history.location.search
          );
          return;
        }

        _challenges.map((c) => {
          c.nutritionist = nutritionists[0];
          return c;
        });

        var grouped = [],
          ignore = {};
        _challenges.map((d) => {
          if (d.previousRounds) {
            d.rounds = d.previousRounds.length + 1;
            d.previousRounds.map((d) => {
              ignore[d] = true;
            });
            d.roundsIds = [d.id].concat(d.previousRounds);
          }
        });

        setIgnore(ignore);
        _challenges = _challenges.filter((d) => !ignore[d.id]);

        if (_challenges.length === 1) {
          //window.location = '/'+_nutritionistName.replace(/ /g, '.')+'/'+_challenges[0].name.replace(/ /g, '.')
          setSelectedChallenge(_challenges[0]);
          setNutritioinistShow(true);

          events.add({
            page: "challenge",
            challenge: { id: _challenges[0].id, name: _challenges[0].name },
            ...(user && { user: { id: user.id, name: user.name } }),
            ...(src && { src: src }),
          });
        } else if (_challenges.length > 1) {
          /* i.e (Eslam Ali):  as per requested by product team, redirect to the latest challenge directly without showing the list */
          history.push(`/challenge/${_challenges[0].id}`);
          return;
          // setNutritioinistShow(true);
          // setChallenges(_challenges);
          // setLoader(false);

          //if(nutritionistsRef.size) events.add({page: 'nutritionist', id: nutritionistsRef.docs[0].id, title: _nutritionistName, ...(user) && {user: {id: user.id, name: user.name}}})
        } else {
          setNutritioinistShow(true);
          setLoader(false);
        }

        //if(challengeId) setSelectedChallenge(_challenges.filter(d=> d.id === challengeId)[0])
        //else setSelectedChallenge(_challenges[0])
      } else {
        if (await isNutOneToOnePublished(_nutritionistName)) {
          history.push(
            `${nutritionistName}/one.to.one` + history.location.search
          );
          return;
        }
        setNutritioinistShow(true);
        setLoader(false);
      }

      //if(window.mixpanel) window.mixpanel.track("nutritionist page", {nutritionist: _nutritionistName});

      if (nutritionistsRef.size)
        events.add({
          page: "nutritionist",
          nutritionist: {
            id: nutritionistsRef.docs[0].id,
            name: _nutritionistName,
          },
          ...(user && { user: { id: user.id, name: user.name } }),
          ...(src && { src: src }),
        });
    }
    /* Getting nutritionist data and challenge data or one to one data */
    if (nutritionistName && challengeName) {
      var _nutritionistName = nutritionistName;
      if (nutritionistName.includes("_"))
        _nutritionistName = nutritionistName
          .split("_")
          .map((d) => d[0].toUpperCase() + d.slice(1))
          .join(" ");
      if (nutritionistName.includes("."))
        _nutritionistName = nutritionistName
          .split(".")
          .map((d) => d[0].toUpperCase() + d.slice(1))
          .join(" ");

      var _challengeName = challengeName;
      if (challengeName.includes("_"))
        _challengeName = challengeName
          .split(
            "_"
          ) /*.map((d)=>d[0].toUpperCase() + d.slice(1).toLowerCase())*/
          .join(" ");
      if (challengeName.includes("."))
        _challengeName = challengeName
          .split(
            "."
          ) /*.map((d)=>d[0].toUpperCase() + d.slice(1).toLowerCase())*/
          .join(" ");

      if (challengeName === "one.to.one") {
        var challengeRef = await firestore("one_to_one")
          .where(
            "nutritionistName",
            "==",
            _nutritionistName
          ) /*.where('name', '==', _challengeName)*/
          .get();
        if (challengeRef.size) {
          var _challenges = challengeRef.docs.map((d) => {
            const obj = d.data();
            obj.id = d.id;
            obj.oneToOne = true;
            return obj;
          }); //.filter((d)=>d.price>0)
          var users = await firestore("users")
            .where(
              "joinedOneToOne." + _challenges[0].id + ".to",
              ">",
              new Date()
            )
            .limit(10000)
            .get();
          if (_challenges[0].max && users.size >= +_challenges[0].max)
            _challenges[0].full = true;
          if (_challenges[0].isPublished) setSelectedChallenge(_challenges[0]);
        }
        if (challengeRef.size)
          events.add({
            page: "program",
            challenge: { id: challengeRef.docs[0].id, name: _nutritionistName },
            ...(user && { user: { id: user.id, name: user.name } }),
            ...(src && { src: src }),
          });
      } else {
        var challengeRef = await firestore("challenges")
          .where(
            "nutritionistName",
            "==",
            _nutritionistName
          ) /*.where('name', '==', _challengeName)*/
          .get();
        if (challengeRef.size) {
          var _challenges = challengeRef.docs.map((d) => {
            const obj = d.data();
            obj.id = d.id;
            return obj;
          }); //.filter((d)=>d.price>0)

          _challenges = _.orderBy(
            _challenges.filter((d) => d.isPublished && !d.isPrivate),
            (item) => moment.utc(item.date).format(),
            "desc"
          );

          var grouped = [],
            ignore = {};
          _challenges.map((d) => {
            if (d.previousRounds) {
              d.rounds = d.previousRounds.length + 1;
              d.previousRounds.map((d) => {
                ignore[d] = true;
              });
              d.roundsIds = [d.id].concat(d.previousRounds);
            }
          });

          setIgnore(ignore);
          _challenges = _challenges.filter((d) => !ignore[d.id]);

          setOtherChallenges(
            _challenges.filter(
              (d) => d.name.toLowerCase() !== _challengeName.toLowerCase()
            )
          );

          setSelectedChallenge(
            _challenges.filter(
              (d) => d.name.toLowerCase() === _challengeName.toLowerCase()
            )[0]
          );

          const challenge = _challenges.filter(
            (d) => d.name.toLowerCase() === _challengeName.toLowerCase()
          )[0];
          //if(window.mixpanel && !(moment(challenge.date).diff(moment(), 'd') > -(parseInt(challenge.grace) || defaultGrace))) window.mixpanel.track("challenge full", {challenge: challenge.name});
        }

        if (challengeRef.size)
          events.add({
            page: "challenge",
            challenge: { id: challengeRef.docs[0].id, name: _challengeName },
            ...(user && { user: { id: user.id, name: user.name } }),
            ...(src && { src: src }),
          });
      }

      //if(window.mixpanel) window.mixpanel.track("challenge page", {challenge: _challengeName});
    }

    return; //[challengeRef, nutritionistsRef, collaboratorRef, reviewsRef, faqRef]
  }

  async function isNutOneToOnePublished(nutName) {
    var challengeRef = await firestore("one_to_one")
      .where("nutritionistName", "==", nutName)
      .get();
    if (
      challengeRef.size &&
      challengeRef.docs[0].data() &&
      challengeRef.docs[0].data().isPublished
    ) {
      return true;
    } else {
      return false;
    }
  }

  async function getChallenge() {
    if (!selectedChallenge) {
      return;
    }

    setLoader(true);
    if (!selectedChallenge.oneToOne) {
      firestore("users")
        .where(
          "collaborate",
          "array-contains",
          selectedChallenge.nutritionistId
        )
        .onSnapshot((collab) => {
          let collabs = [];
          collab.docs.map((col) => {
            collabs.push(col.data());
          });
          setCollaborators(_.orderBy(collabs, (item) => item.ratings, "desc"));
        });
    }

    const challengeRef = firestore(
      selectedChallenge.oneToOne ? "one_to_one" : "challenges"
    )
      .doc(selectedChallenge.id)
      .onSnapshot((documentSnapshot) => {
        var obj = documentSnapshot.data();
        obj.id = documentSnapshot.id;

        setChallenge(obj);

        setLoader(false);
      });

    const nutritionistsRef = selectedChallenge.nutritionistId
      ? firestore("users")
          .doc(selectedChallenge.nutritionistId)
          .onSnapshot((documentSnapshot) => {
            setNutritioinist(documentSnapshot.data());
          })
      : () => {};

    const reviewsRef = firestore("reviews")
      .where(
        "challengeId",
        "in",
        [selectedChallenge.id]
          .concat(selectedChallenge.previousRounds || [])
          .slice(0, 10)
      )
      .orderBy("datetime", "desc")
      .limit(200)
      .onSnapshot((documentSnapshot) => {
        //const reviewsRef = firestore("reviews").where('nutritionistId', '==', selectedChallenge.nutritionistId).onSnapshot((documentSnapshot) => {

        var docs = documentSnapshot.docs.map((d) => {
          var obj = d.data();
          obj.id = d.id;
          return obj;
        });

        setReviews(docs);
      });

    const faqRef = firestore(
      selectedChallenge.oneToOne ? "one_to_one" : "challenges"
    )
      .doc(selectedChallenge.id)
      .collection("faq")
      .onSnapshot((documentSnapshot) => {
        var docs = documentSnapshot.docs.map((d) => {
          var obj = d.data();
          obj.id = d.id;
          return obj;
        });

        setFaqs(docs);
      });

    return [challengeRef, nutritionistsRef, reviewsRef, faqRef];
  }

  function fbImage(imageLink) {
    if (!imageLink) return profileImg;
    var query = imageLink.split("asid=")[1];
    if (!query) return imageLink;
    var fbId = query.split("&")[0];
    if (!fbId) return imageLink;

    return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`;
  }

  async function logout() {
    localStorage.clear();
    await firebase.auth().signOut();
  }

  async function goToChallenge(challenge) {
    //const nutritionistsRef = await firestore("users").doc(challenge.nutritionistId).get()
    //const nutritionist = nutritionistsRef.data()
    //window.open('/'+challenge.nutritionistName.replace(/ /g, '.')+'/'+challenge.name.replace(/ /g, '.'))
    window.open(`/challenge/${challenge.id}`);
  }

  async function goToOneToOne(nutritionist) {
    window.open("/" + nutritionist.name.replace(/ /g, ".") + "/one.to.one");
  }

  async function goToNutritionist(nutritionist) {
    //const nutritionistsRef = await firestore("users").doc(challenge.nutritionistId).get()
    //const nutritionist = nutritionistsRef.data()
    //window.open('/'+nutritionist.name.replace(/ /g, '.')+'/'+challenge.name.replace(/ /g, '.'))
    window.open("/" + nutritionist.name.replace(/ /g, "."));
  }

  const checkIsUserFollowing = async () => {
    if (!user) return;

    const followingDoc = await firestore("users")
      .doc(nutritionist.id)
      .collection("followers")
      .doc(user?.id)
      .get();
    if (followingDoc.exists) {
      setIsUserFollowing(true);
    } else {
      setIsUserFollowing(false);
    }
  };

  const follow = async () => {
    if (!user) {
      setShowLoginAlert(true);
      return;
    }
    //if(!user) return setShowLogin(true)

    await setIsUserFollowing(!isUserFollowing);
    const userRef = await firestore("users")
      .doc(nutritionist.id)
      .collection("followers")
      .doc(user.id);

    if (isUserFollowing) {
      await userRef.delete();

      var _nutritionist = _.clone(nutritionist);
      _nutritionist.followersCount--;
      setNutritioinist(_nutritionist);
    } else {
      await userRef.set({});

      var _nutritionist = _.clone(nutritionist);
      _nutritionist.followersCount++;
      setNutritioinist(_nutritionist);
    }
  };

  const ensureFollow = async () => {
    const userRef = firestore("users")
      .doc(nutritionist.id)
      .collection("followers")
      .doc(user?.id);
    //.get();

    const userDoc = await userRef.get();

    if (!userDoc.exists) {
      await userRef.set({});

      var _nutritionist = _.clone(nutritionist);
      _nutritionist.followersCount++;
      setNutritioinist(_nutritionist);
    }

    setIsUserFollowing(true);
  };

  const followCb = () => {
    alert("f");
  };

  const isArabic = (txt, threshold = 50) => {
    if (!txt || !txt.length) return 0;

    var arabic = /[\u0600-\u06FF]/;
    var count = 0;
    for (var i = 0; i < txt.length; i++)
      count += arabic.test(txt.charAt(i)) ? 1 : 0;

    return (count / txt.length) * 100 > threshold;
  };

  const challengeDetails = (
    <Row className="challenge-details">
      <Tabs
        className="tabs nav-justified"
        defaultActiveKey={"REVIEWS"}
        id="uncontrolled-tab-example"
      >
        {/*<Tab className="tab about" eventKey="ABOUT" title="About">
                                    {challenge.welcome? challenge.welcome.map((line)=><div>{line}</div>):null}
                                </Tab>*/}
        <Tab className="tab reviews" eventKey="REVIEWS" title="Reviews">
          {reviews
            ? reviews.map((review) => (
                <div className="review">
                  <div className="review-date">
                    {moment(review.datetime).fromNow()}
                  </div>
                  <img
                    className="review-userImage"
                    height="60"
                    src={fbImage(review.userImage)}
                  />
                  <div
                    className="review-userName"
                    onClick={
                      user && user.role && user.role.includes("admin")
                        ? () => {
                            window.open(
                              "https://dashboard.welnes.app/support/" +
                                review.userId
                            );
                          }
                        : () => {}
                    }
                  >
                    {review.userName}
                  </div>
                  <div className="review-rating">
                    <StarRatings
                      rating={review.rating}
                      starDimension="15px"
                      starSpacing="2px"
                      starRatedColor="#ffb400"
                      numberOfStars={5}
                      name="rating"
                    />
                    <span>{(review.rating || 0).toFixed(2)} </span>
                  </div>
                  <div className="review-caption">{review.caption}</div>
                  {review.beforeImage && review.afterImage && (
                    <div className="review-image">
                      <div>
                        <img src={review.beforeImage} />
                        <figcaption>Before</figcaption>
                      </div>
                      <div>
                        <img src={review.afterImage} />
                        <figcaption>After</figcaption>
                      </div>
                    </div>
                  )}
                </div>
              ))
            : null}
        </Tab>
        {nutritionist && (
          <Tab className="tab coach" eventKey="COACH" title="Coach">
            {nutritionist.nutritionistBio}
          </Tab>
        )}
        <Tab className="tab faqs" eventKey="FAQs" title="FAQs">
          {faqs
            ? faqs.map((faq) => (
                <div className="faq">
                  <div className="faq-question">{faq.question}</div>
                  <div className="faq-answer">{faq.answer}</div>
                </div>
              ))
            : null}
        </Tab>
      </Tabs>
    </Row>
  );

  const handleClose = () => setShowLoginAlert(false);

  const routeToLogin = () => {
    history.push("/" + nutritionistName + "/follow" + history.location.search);
  };

  return (
    <>
      <div id="Header" className={nutritioinistShow && "nutritioinist-show"}>
        <Container fluid="md">
          <Row className="header justify-content-md-left">
            <div className="main">
              <img height="40" src={logoImg} />
              {allChallenges && (
                <>
                  <span>Challenges</span>
                  <span
                    style={{
                      cursor: "pointer",
                      marginLeft: "2em",
                      textDecoration: "underline",
                      color: "#d56d00",
                      fontWeight: "normal",
                    }}
                    onClick={() => {
                      window.open("https://welnes.blog");
                    }}
                  >
                    Blog
                  </span>
                </>
              )}
            </div>

            {selectedChallenge && (
              <div
                className="see-all"
                onClick={() => {
                  window.location = "/challenges";
                }}
              >
                <FontAwesomeIcon icon={faBars} size="1x" className="mr-2" />
                <span>See all challenges</span>
              </div>
            )}

            {user && (
              <div className="account">
                <NavLink
                  key={"/profile"}
                  to={"/profile"}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    className="user-image"
                    style={{ cursor: "pointer" }}
                    height="90"
                    src={fbImage(user.image)}
                  />
                  <div
                    className="user-name"
                    style={{ color: "#464646", fontWeight: "bold" }}
                  >
                    {user.name}
                  </div>
                </NavLink>

                {/*<Button className="sign-out" size="sm" variant="primary" onClick={() => logout()}>Sign out</Button>*/}
              </div>
            )}
          </Row>

          {nutritioinistShow && (
            <Row id="Nutritionist" className="content">
              <img
                className="image"
                width="200"
                height="200"
                src={fbImage(nutritionist.image)}
              />
              <div className="details">
                {oneToOne && oneToOne.isPublished && (
                  <Button
                    style={{ float: "right", backgroundColor: "#1F516F" }}
                    onClick={() => {
                      goToOneToOne(nutritionist);
                    }}
                  >
                    Join one to one program
                  </Button>
                )}
                <span className="name">
                  {nutritionist.name}
                  <span className="follow top">
                    <Button
                      variant="dark"
                      className={!isUserFollowing ? "" : "following"}
                      style={
                        !isUserFollowing
                          ? { background: "#1F516F" }
                          : { background: "#1F516F" }
                      }
                      onClick={follow}
                    >
                      {!isUserFollowing ? "Follow" : "Following"}
                    </Button>
                    <b>
                      {nutritionist.followersCount > 1000
                        ? `${Math.ceil(nutritionist.followersCount / 1000)}K`
                        : nutritionist.followersCount}
                    </b>{" "}
                    followers
                  </span>
                </span>
                <span className="title">{nutritionist.nutritionistTitle} </span>
                <span className="rating">
                  <StarRatings
                    rating={nutritionist.rating}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="#ffb400"
                    numberOfStars={5}
                    name="rating"
                  />
                  <span>{(nutritionist.rating || 0).toFixed(2)} </span>
                  <span>({nutritionist.ratings} ratings) </span>
                </span>
                <span className="follow bottom">
                  <Button
                    variant="dark"
                    className={!isUserFollowing ? "" : "following"}
                    style={
                      !isUserFollowing
                        ? { background: "#1F516F" }
                        : { background: "#1F516F" }
                    }
                    onClick={follow}
                  >
                    {!isUserFollowing ? "Follow" : "Following"}
                  </Button>
                  <b>
                    {nutritionist.followersCount > 1000
                      ? `${Math.ceil(nutritionist.followersCount / 1000)}K`
                      : nutritionist.followersCount}
                  </b>{" "}
                  followers
                </span>
                <span className="bio">{nutritionist.nutritionistBio} </span>
              </div>
            </Row>
          )}
        </Container>
      </div>

      {loader ? (
        <div className="loader text-center">
          <Spinner animation="border" size={"sm"} role="status" />
        </div>
      ) : (
        <>
          {challenges && (
            <div id="Challenges" className="">
              <Container fluid="md">
                <Row className="content">
                  <Col className="main" md={12}>
                    {allChallenges && <h4>All Challenges</h4>}

                    {challenges && (
                      <>
                        <Row>
                          {challenges &&
                            challenges.map((challenge, i) => {
                              return (
                                <div className="challenge">
                                  <div
                                    className="challenge-image"
                                    style={{
                                      backgroundImage:
                                        "url(" + challenge.image + ")",
                                    }}
                                  ></div>
                                  <div className="info">
                                    <Button
                                      variant="dark"
                                      onClick={() => {
                                        goToChallenge(challenge);
                                      }}
                                    >
                                      See more
                                    </Button>

                                    <div className="title">
                                      <div
                                        style={{
                                          maxWidth: "30%",
                                          display: "inline-block",
                                          whiteSpace: "nowrap",
                                          overflowX: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {challenge.name}
                                      </div>
                                      <span className="challenge-round">
                                        {challenge.previousRounds
                                          ? "round " +
                                            (challenge.previousRounds.length +
                                              1)
                                          : "round 1"}
                                      </span>
                                      <span className="rating">
                                        <StarRatings
                                          rating={challenge.roundsRating}
                                          starDimension="15px"
                                          starSpacing="1px"
                                          starRatedColor="#ffb400"
                                          numberOfStars={5}
                                          name="rating"
                                        />
                                        <span>
                                          {(
                                            challenge.roundsRating || 0
                                          ).toFixed(2)}{" "}
                                        </span>
                                        <span>
                                          ({challenge.roundsRatings} ratings){" "}
                                        </span>
                                      </span>
                                    </div>

                                    <div className="date">
                                      {moment(challenge.date).isAfter()
                                        ? "Starting "
                                        : "Started "}
                                      {moment(challenge.date).format("Do")} of{" "}
                                      {moment(challenge.date).format("MMMM")}
                                    </div>

                                    <div className="description">
                                      {challenge.description}
                                    </div>

                                    <div className="interval">
                                      <FontAwesomeIcon
                                        icon={faCalendarAlt}
                                        size="1x"
                                        className="mr-2"
                                      />
                                      {moment(challenge.date).format("Do")} of{" "}
                                      {moment(challenge.date).format("MMMM")} to
                                      {" " +
                                        moment(challenge.date)
                                          .add(
                                            challenge.duration,
                                            challenge.durationType
                                              .replace("s", "")
                                              .toLowerCase()
                                          )
                                          .format("Do")}{" "}
                                      of
                                      {" " +
                                        moment(challenge.date)
                                          .add(
                                            challenge.duration,
                                            challenge.durationType
                                              .replace("s", "")
                                              .toLowerCase()
                                          )
                                          .format("MMMM")}
                                    </div>

                                    {challenge.nutritionist && (
                                      <div className="nutritionist" md={12}>
                                        <img
                                          className="image"
                                          height="50"
                                          width="50"
                                          src={fbImage(
                                            challenge.nutritionist.image
                                          )}
                                          onClick={() => {
                                            goToNutritionist(
                                              challenge.nutritionist
                                            );
                                          }}
                                        />
                                        <div className="details">
                                          <span className="name">
                                            {challenge.nutritionist.name}
                                          </span>
                                          <span className="title">
                                            {
                                              challenge.nutritionist
                                                .nutritionistTitle
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
              </Container>
            </div>
          )}
          {challenge && (
            <div id="Challenge" className="">
              <Container fluid="md">
                {challenge ? (
                  <Row className="content">
                    <Col className="main" md={8}>
                      <div className="title">
                        {challenge.oneToOne ? (
                          <span className="name">
                            One to One follow up
                            <span
                              style={{
                                fontWeight: "normal",
                                fontSize: ".8em",
                                margin: "0 .3em",
                              }}
                            >
                              {" "}
                              with{" "}
                            </span>
                            {challenge.nutritionistName}
                          </span>
                        ) : (
                          <span className="name">
                            {challenge.name}
                            <span className="challenge-round">
                              {challenge.previousRounds
                                ? "round " +
                                  (challenge.previousRounds.length + 1)
                                : "round 1"}
                            </span>
                          </span>
                        )}
                        {!challenge.oneToOne && (
                          <span className="duration">
                            {challenge.duration +
                              " " +
                              (challenge.durationType
                                ? challenge.durationType.toLowerCase()
                                : "")}
                          </span>
                        )}
                      </div>
                      {challenge.videoLink ? (
                        <div className="video">
                          <ReactPlayer
                            className="react-player"
                            id="file-video"
                            playing
                            controls
                            light={challenge.image}
                            url={challenge.videoLink.replace(
                              "/shorts/",
                              "/watch?v="
                            )}
                            alt="video preview"
                            width="100%"
                            height="100%"
                          />
                          <span className="name">{challenge.description} </span>
                        </div>
                      ) : challenge.image ? (
                        <img
                          className="challenge-image-img"
                          src={challenge.image}
                        />
                      ) : null}
                      <div className="description">
                        <div
                          className="name"
                          style={
                            isArabic(challenge.description)
                              ? {
                                  textAlign: "right",
                                  direction: "rtl",
                                  whiteSpace: "pre-line",
                                }
                              : { whiteSpace: "pre-line" }
                          }
                        >
                          {challenge.description}{" "}
                        </div>
                      </div>
                      <Row className="nutritionists">
                        {nutritionist && (
                          <Col
                            className="nutritionist"
                            md={collaborators.length > 1 ? 12 : 6}
                          >
                            <img
                              className="image"
                              height="70"
                              width="70"
                              src={fbImage(nutritionist.image)}
                            />
                            <div className="details">
                              <span className="name">{nutritionist.name} </span>
                              <span className="title">
                                {nutritionist.nutritionistTitle}{" "}
                              </span>
                              <span className="rating">
                                <StarRatings
                                  rating={nutritionist.rating}
                                  starDimension="15px"
                                  starSpacing="1px"
                                  starRatedColor="#ffb400"
                                  numberOfStars={5}
                                  name="rating"
                                />
                                <span>
                                  {(nutritionist.rating || 0).toFixed(2)}{" "}
                                </span>
                                <span>({nutritionist.ratings} ratings) </span>
                              </span>
                            </div>
                          </Col>
                        )}
                        {collaborators.map((coll) => {
                          return (
                            <Col className="nutritionist collaborator" md={6}>
                              <img
                                className="image"
                                height="70"
                                width="70"
                                src={fbImage(coll.image)}
                              />
                              <div className="details">
                                <span className="name">{coll.name} </span>
                                <span className="title">
                                  {coll.nutritionistTitle}{" "}
                                </span>
                                <span className="rating">
                                  <StarRatings
                                    rating={coll.rating}
                                    starDimension="15px"
                                    starSpacing="1px"
                                    starRatedColor="#ffb400"
                                    numberOfStars={5}
                                    name="rating"
                                  />
                                  <span>{(coll.rating || 0).toFixed(2)} </span>
                                  <span>({coll.ratings} ratings) </span>
                                </span>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                      <Row className="separator"></Row>
                      {challengeDetails}
                    </Col>
                    <Col className="side" md={4}>
                      <div className="features">
                        <div className="header">
                          {challenge.oneToOne
                            ? "Program Features"
                            : "Challenge Features"}
                        </div>
                        {challenge.features
                          ? Object.keys(
                              featuresMap[
                                challenge.oneToOne ? "program" : "challenge"
                              ]
                            )
                              .filter((feature) => challenge.features[feature])
                              .map((feature) => (
                                <div className="feature" key={feature}>
                                  <img
                                    className="icon"
                                    src={
                                      featuresMap[
                                        challenge.oneToOne
                                          ? "program"
                                          : "challenge"
                                      ][feature].image
                                    }
                                  />
                                  <span>
                                    {
                                      featuresMap[
                                        challenge.oneToOne
                                          ? "program"
                                          : "challenge"
                                      ][feature].title
                                    }
                                  </span>
                                </div>
                              ))
                          : null}
                      </div>
                      <div className="round">
                        {<div className="header">Program Fees</div>}
                        {(challenge.oneToOne && !selectedChallenge.full) ||
                        moment(challenge.date).diff(moment(), "d") >
                          -(parseInt(challenge.grace) || defaultGrace) ? (
                          <Col className="join" md={12}>
                            {user &&
                            ((user.joinedChallengeIds &&
                              user.joinedChallengeIds.includes(
                                selectedChallenge.id
                              )) ||
                              (user.joinedOneToOneIds &&
                                user.joinedOneToOneIds.includes(
                                  selectedChallenge.id
                                ) &&
                                new Date(
                                  user.joinedOneToOne[selectedChallenge.id].to
                                ) > new Date())) ? (
                              <span className="price"></span>
                            ) : challenge.isFree ? (
                              <span className="price">Free</span>
                            ) : location && location === "EG" ? (
                              <>
                                {challenge.isBundleable ? (
                                  <>
                                    {Object.entries(challenge.bundles).map(
                                      ([key, value], index) => {
                                        const discountValue = value
                                          ? key * (1 - value / 100)
                                          : 1;
                                        return (
                                          <>
                                            <span className="price">
                                              <span style={{ color: "black" }}>
                                                {key +
                                                  ` month${key > 1 ? "s" : ""}${
                                                    !!Number(value)
                                                      ? ` (${value}%)`
                                                      : ""
                                                  } = `}
                                              </span>
                                              {Math.round(
                                                challenge.price * discountValue
                                              ).toLocaleString()}{" "}
                                              EGP
                                            </span>
                                            <br />
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <span className="price">
                                    {challenge.price} EGP
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                {challenge.isBundleable ? (
                                  <>
                                    {Object.entries(challenge.bundles).map(
                                      ([key, value], index) => {
                                        const discountValue = value
                                          ? key * (1 - value / 100)
                                          : 1;
                                        return (
                                          <>
                                            <span className="price">
                                              <span style={{ color: "black" }}>
                                                {key +
                                                  ` month${
                                                    key > 1 ? "s" : ""
                                                  } = `}
                                              </span>
                                              $
                                              {Math.round(
                                                challenge.price_usd *
                                                  discountValue
                                              )}{" "}
                                              USD
                                            </span>
                                            <br />
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <span className="price">
                                    ${challenge.price_usd} USD
                                  </span>
                                )}
                              </>
                            )}

                            {challenge.oneToOne && (
                              <div style={{ height: "1em" }} />
                            )}

                            {!challenge.oneToOne && (
                              <div className="interval">
                                <FontAwesomeIcon
                                  icon={faCalendarAlt}
                                  size="1x"
                                  className="mr-2"
                                />
                                {moment(challenge.date).format("Do")} of{" "}
                                {moment(challenge.date).format("MMMM")} to
                                {" " +
                                  moment(challenge.date)
                                    .add(
                                      challenge.duration,
                                      challenge.durationType
                                        .replace("s", "")
                                        .toLowerCase()
                                    )
                                    .format("Do")}{" "}
                                of
                                {" " +
                                  moment(challenge.date)
                                    .add(
                                      challenge.duration,
                                      challenge.durationType
                                        .replace("s", "")
                                        .toLowerCase()
                                    )
                                    .format("MMMM")}
                              </div>
                            )}

                            <NavLink
                              className="hover-blur mw-50 "
                              //onClick={(e)=>{if(!user){setShowLogin(challenge.oneToOne? `/checkout/followup/${challenge.id}` : `/checkout/challenge/${challenge.id}`); e.preventDefault();}}}
                              key={
                                challenge.oneToOne
                                  ? `/checkout/followup/${challenge.id}`
                                  : `/checkout/challenge/${challenge.id}`
                              }
                              to={
                                challenge.oneToOne
                                  ? `/checkout/followup/${challenge.id}`
                                  : `/checkout/challenge/${challenge.id}` +
                                    (challenge.name
                                      .toLowerCase()
                                      .includes("etisalat")
                                      ? "/etisalat"
                                      : "")
                              }
                            >
                              <button>
                                {user &&
                                ((user.joinedChallengeIds &&
                                  user.joinedChallengeIds.includes(
                                    selectedChallenge.id
                                  )) ||
                                  (user.joinedOneToOneIds &&
                                    user.joinedOneToOneIds.includes(
                                      selectedChallenge.id
                                    ) &&
                                    new Date(
                                      user.joinedOneToOne[
                                        selectedChallenge.id
                                      ].to
                                    ) > new Date()))
                                  ? "Start"
                                  : "Join Now"}
                              </button>
                            </NavLink>
                          </Col>
                        ) : (
                          <Col className="join" md={12}>
                            <span
                              className="price"
                              style={{ fontSize: "12px", color: "#222" }}
                            >
                              Download the app to join the next{" "}
                              {challenge.oneToOne ? "Program" : "Challenge"}!
                            </span>

                            {!challenge.oneToOne && (
                              <div className="interval">
                                <FontAwesomeIcon
                                  icon={faCalendarAlt}
                                  size="1x"
                                  className="mr-2"
                                />
                                {moment(challenge.date).format("Do")} of{" "}
                                {moment(challenge.date).format("MMMM")} to
                                {" " +
                                  moment(challenge.date)
                                    .add(
                                      challenge.duration,
                                      challenge.durationType
                                        .replace("s", "")
                                        .toLowerCase()
                                    )
                                    .format("Do")}{" "}
                                of
                                {" " +
                                  moment(challenge.date)
                                    .add(
                                      challenge.duration,
                                      challenge.durationType
                                        .replace("s", "")
                                        .toLowerCase()
                                    )
                                    .format("MMMM")}
                              </div>
                            )}

                            <NavLink
                              className="hover-blur mw-50 "
                              key={`/`}
                              to={`/`}
                            >
                              <button style={{}}>Download App</button>
                            </NavLink>
                          </Col>
                        )}
                        <span className="secure-note">
                          Secure payment using credit card
                        </span>
                      </div>
                      {otherChallenges && otherChallenges.length ? (
                        <div className="other-challenges">
                          <div className="header">Other Challenges</div>
                          {otherChallenges.map((challenge) => (
                            <div
                              className="challenge"
                              key={challenge.id}
                              onClick={() => {
                                goToChallenge(challenge);
                              }}
                            >
                              <div
                                className="challenge-image"
                                style={{
                                  backgroundImage:
                                    "url(" + challenge.image + ")",
                                }}
                              ></div>
                              <div className="info">
                                <div className="title">{challenge.name}</div>

                                <span className="rating">
                                  <StarRatings
                                    rating={challenge.roundsRating}
                                    starDimension="15px"
                                    starSpacing="1px"
                                    starRatedColor="#ffb400"
                                    numberOfStars={5}
                                    name="rating"
                                  />
                                  <span>
                                    {(challenge.roundsRating || 0).toFixed(2)}{" "}
                                  </span>
                                  <span>
                                    ({challenge.roundsRatings} ratings){" "}
                                  </span>
                                </span>

                                <div className="date">
                                  {moment(challenge.date).isAfter()
                                    ? "Starting "
                                    : "Started "}
                                  {moment(challenge.date).format("Do")} of{" "}
                                  {moment(challenge.date).format("MMMM")}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : null}
                      {/*<div className="social">
                            <div className="Share">Share via</div>
                            <span><img className="icon" src={facebookIcon} onClick={()=>{window.open('https://www.facebook.com/sharer/sharer.php?u=https://dieters.app/challenge/BxJrLodphj9UmLvl377t')}}/></span>
                            <span><img className="icon" src={twitterIcon} onClick={()=>{window.open('https://twitter.com/home?status=https://dieters.app/challenge/BxJrLodphj9UmLvl377t ')}}/></span>
                            <span><img className="icon" src={instagramIcon} onClick={()=>{}}/></span>
                            <span><img className="icon" src={youtubeIcon} onClick={()=>{window.open(challenge.videoLink)}}/></span>
                        </div>*/}
                    </Col>
                    <Col className="main bottom" md={8}>
                      {challengeDetails}
                    </Col>

                    {(challenge.oneToOne && !selectedChallenge.full) ||
                    moment(challenge.date).diff(moment(), "d") >
                      -(parseInt(challenge.grace) || defaultGrace) ? (
                      <Col className="join-float" md={6}>
                        {/* {user &&
                        ((user.joinedChallengeIds &&
                          user.joinedChallengeIds.includes(
                            selectedChallenge.id
                          )) ||
                          (user.joinedOneToOneIds &&
                            user.joinedOneToOneIds.includes(
                              selectedChallenge.id
                            ) &&
                            new Date(
                              user.joinedOneToOne[selectedChallenge.id].to
                            ) > new Date())) ? (
                          <span className="price"></span>
                        ) : challenge.isFree ? (
                          <span className="price">Free</span>
                        ) : location && location === "EG" ? (
                          <span className="price">{challenge.price} EGP</span>
                        ) : (
                          <span className="price">
                            ${challenge.price_usd} USD
                          </span>
                        )} */}
                        <NavLink
                          className="hover-blur mw-50 "
                          //onClick={(e)=>{if(!user){setShowLogin(challenge.oneToOne? `/checkout/followup/${challenge.id}` : `/checkout/challenge/${challenge.id}`); e.preventDefault();}}}
                          key={
                            challenge.oneToOne
                              ? `/checkout/followup/${challenge.id}`
                              : `/checkout/challenge/${challenge.id}`
                          }
                          to={
                            challenge.oneToOne
                              ? `/checkout/followup/${challenge.id}`
                              : `/checkout/challenge/${challenge.id}` +
                                (challenge.name
                                  .toLowerCase()
                                  .includes("etisalat")
                                  ? "/etisalat"
                                  : "")
                          }
                        >
                          <button style={{ width: user ? "60%" : "60%" }}>
                            {user &&
                            ((user.joinedChallengeIds &&
                              user.joinedChallengeIds.includes(
                                selectedChallenge.id
                              )) ||
                              (user.joinedOneToOneIds &&
                                user.joinedOneToOneIds.includes(
                                  selectedChallenge.id
                                ) &&
                                new Date(
                                  user.joinedOneToOne[selectedChallenge.id].to
                                ) > new Date()))
                              ? "Start"
                              : "Join Now"}
                          </button>
                        </NavLink>
                      </Col>
                    ) : (
                      <Col className="join-float" md={6}>
                        <span
                          className="note"
                          style={{ fontSize: "12px", color: "222" }}
                        >
                          Download the app to join next{" "}
                          {challenge.oneToOne ? "Program" : "Challenge"}!
                        </span>
                        <NavLink
                          className="hover-blur mw-50 "
                          key={`/`}
                          to={`/`}
                        >
                          <button style={{ width: user ? "60%" : "100%" }}>
                            Download App
                          </button>
                        </NavLink>
                      </Col>
                    )}
                  </Row>
                ) : (
                  <div style={{ padding: "2em 0" }}>
                    {/*nutritionist has no published challenges yet*/}
                  </div>
                )}
              </Container>
            </div>
          )}
        </>
      )}
      <Modal show={showLogin} onHide={() => setShowLogin(false)}>
        <Login
          handleClose={() => setShowLogin(false)}
          challenge={challenge}
          callback={(user) => {
            const redirectURI = showLogin;
            setShowLogin(false);
            setUser(user);
            if (redirectURI.includes("/")) window.location = redirectURI;
          }}
        />
      </Modal>
      <Modal show={showLoginAlert} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Follow {nutritionist?.name}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You need to login to follow {nutritionist?.name}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            style={{ backgroundColor: "#1F516F" }}
            onClick={routeToLogin}
          >
            Login
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
